<template>
    <div class="page-gray">
        <div class="top">
            <img src="./../../assets/images/order/submitOrder/paySuccess.png" alt="">
            <p>支付成功</p>

            <div class="buttonGroup">
                <div>查看订单</div>
                <div @click="$router.replace('/')">返回首页</div>
            </div>
        </div>
        <div class="mall-box">
            <integral-mall type="grid" />
        </div>
    </div>
</template>

<script>
import integralMall from '../../components/integralMall.vue'
export default {
  components: { integralMall },
    name: "PaySuccess"
}

</script>
<style lang="less" scoped>
.top {
    height: 230px;
    background-color: #fff;
    text-align: center;

    img {
        height: 53px;
        margin-top: 52px;
        margin-bottom: 18px;
    }
    p {
        line-height: 14px;
        font-size: 14px;
        color: #212121;
        margin-bottom: 34px;
    }

    .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        div {
            border-radius: 13px;
            padding: 6px 18px;
            line-height: 14px;
            font-size: 14px;
            &:first-child {
                color: #212121;
                background: #FFFFFF;
                border: 1px solid #CCCCCC;
                margin-right: 40px;
            }
            &:last-child {
                color: #fff;
                background: #D53A26;
                border: 1px solid #D53A26;
            }
        }
    }

}
.mall-box {
    padding: 8px;
}
</style>