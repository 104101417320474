<template>
    <div class="IntegralMall">
       <div class="headBox">
            <div class="head">
                <div class="left">
                    <img src="./../assets/images/components/jf.png" alt="">
                    <span class="name">积分兑换</span>
                    <span class="line"></span>
                    <span class="tips">第三方服务</span>
                </div>

                <div class="right">
                    <span>我的积分:</span>
                    <span class="number">1000</span>
                    <img src="./../assets/images/components/tips.png" alt="">
                </div>
            </div>
       </div>

        <div class="content" v-if="type === 'swiper'">
            <div class="prev" @click="prev">
                <van-icon name="arrow-left"  color="#666"/>
            </div>
             <div class="prevMask"></div>
            <div class="list" ref="list">
                <div class="listContent">
                    <div class="item" v-for="item in 10">
                        <img src="./../assets/images/components/goods.png" alt="">
                        <p>41.2积分+{{item}}元</p>
                    </div>
                </div>
            </div>
            <div class="next" @click="next">
               <van-icon name="arrow"  color="#666"/>
            </div>
            <div class="nextMask"></div>
        </div>

        <div class="grid-content" v-if="type === 'grid'">
            <div class="item" v-for="item in 6">
                <div class="item-content">
                    <img src="./../assets/images/components/goods.png" alt="">
                    <p class="name">得力9591文具考试绘画套装（八件套）</p>
                    <p class="dis">积分最高可抵8% </p>
                    <p class="sheng">省5.26元</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BScroll from 'better-scroll'

export default {
    name: "IntegralMall",
    props: {
        type: {
            default: 'swiper', // grid
        }
    },
    data() {
        return {
            bs: null
        }
    },
    beforeDestroy() {
        if (this.bs) {
            this.bs.destroy()
        }
    },
    methods: {
        next() {
            let offset = this.bs.x - this.$refs.list.offsetWidth;
            if (offset < this.bs.maxScrollX) {
                offset = this.bs.maxScrollX - 40
            }
            this.bs.scrollTo(offset , 0, 300)
        },

        prev() {
            let offset = this.bs.x + this.$refs.list.offsetWidth
            if (offset > (this.$refs.list.offsetWidth / 2)) {
                offset = 40
            }
            this.bs.scrollTo(offset , 0, 300)
        }
    },

    mounted() {
        if (this.type === 'swiper') {
            this.bs = new BScroll(this.$refs.list, {
                scrollX: true,
            })
        }
    }
}

</script>
<style lang="less" scoped>
.IntegralMall {
    border-radius: 8px;
    margin: 10px;
    .headBox {
        height: 80px;
        background: linear-gradient(179deg, #FFF8E4, #F4F4F4);
        border-radius: 8px 8px 0px 0px;
    }
    .head {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 18px;
        
        

        .left {
            display: flex;
            align-items: center;
            img {
                height: 18px;
                width: 18px;
                margin-right: 6px;
            }

            .name {
                font-size: 15px;
                font-weight: bold;
                color: #222222;
                line-height: 15px;
            }

            .line {
                margin: 0 9px;
                width: 1px;
                height: 12px;
                background-color: #333;
            }

            .tips {
                font-size: 10px;
                font-weight: 400;
                color: #333333;
            }
        }


        .right {
            background: #FFECCF;
            border-radius: 119px;
            padding: 4px 5px 4px 11px;
            display: flex;
            align-items: center;

            span {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
            }
            .number {
                color: #EA7C33;
            }
            img {
                height: 12px;
                width: 12px;
                margin-left: 5px;
            }
        }
    }


    .content {
        background-color: #fff;
        border-radius: 8px;
        margin-top: -40px;
        position: relative;
        .prev, .next {
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(176, 176, 176, 0.5);
            border-radius: 50%;
            height: 22px;
            width: 22px;
            position: absolute;

            top: 50%;
            transform: translateY(-50%);

            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
        }

        .prev {
            left: 21px;
        }
        .next {
            right: 21px;
        }


        .nextMask {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 30px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 80%)
        }
        .list {
            padding: 16px 19px;
            white-space: nowrap;
            overflow-x: hidden;
            .listContent {
                display: inline-block;
            }
            .item {
                margin-right: 15px;
                display: inline-block;
                img {
                    width: 110px;
                    height: 92px;
                }

                p {
                    font-size: 9px;
                    font-weight: 500;
                    color: #FA591F;
                    line-height: 1;
                    width: 110px;
                    text-align: center;
                }
            }
        }
    }
}

.grid-content {
    background-color: #fff;
    border-radius: 8px;
    margin-top: -40px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 33.33%;
        padding-bottom: 20px;
        .item-content {
            width: 103px;
            margin: 0 auto;
            img {
                width: 103px;
                height: 86px;
                border-radius: 5px;
            }

            p.name {
                font-size: 10px;
                color: #212121;
                line-height: 13px;
                margin-top: 9px;
                margin-block: 6px;
            }

            p.dis, p.sheng {
                font-size: 9px;
                color: #FA591F;
                line-height: 14px;
            }
        }
    }
}
</style>
